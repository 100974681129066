<template>
    <h2>KNACKTIVE</h2>
    <div class="content">
        <h1>ABOUT</h1><br>
        <p>Knacktive is an advanced, undergraduate, course/agency experience at Northwest Missouri State University.  The elective course is designed to replicate the creative demands and 
            intense teamwork atmosphere of a technology-oriented, professional marketing communications agency.  Students selected for Knacktive actively refine their creative abilities, 
            collaborative skills, and academic knowledge; with the objective of improved competitiveness in the professional marketplace after graduation.  At the same time, Knacktive answers 
            a need identified by business leaders for students who understand working in a competitive, creative team environment. Students experience a realistic, agency-like atmosphere as 
            they work with multiple teammates on a promotional campaign plan for a real client.  The competing teams conduct research, analyze the data, devise a strategy, formulate tactics, 
            and ultimately make a formal presentation of their plan to the client.</p><br>
        <h1>CLIENT</h1><br>
        <p>The client for 2020 was Farmer's Mutual Insurance of Nodaway County.</p><br>
        <h1>TEAM IN OUR PAWS</h1><br>
        <p>A Knacktive team consists of a Project Manager, Digital Manager, Media Manager, Public Relations Manager, Marketing Strategist, Art Director, Multi-Platform Content Creator, 
            and a Graphic Designer. I was the Graphic Designer in our group, and I was in charge of creating the new logo, promotional content, and website mock-up.</p><br>
        <p>As a first foray into the semester, teams were required to think of a name and logo, and participate in team building exercises (Not to brag, but our team had the highest paper tower). 
            Although a small task, creating the logo for our team was a first taste as to what was to come. It required communication and concepting among team members.</p><br>
        <div class="imagecontainer">
            <div><img src="../assets/inourpaws-01.png"></div>
            <div><img src="../assets/team.jpg"></div>
        </div><br>
        <h1>RESEARCH</h1><br>
        <p>Farmer's Mutual was looking for a complete rebrand. They wanted to appeal to a younger audience to grow their business by increasing renter's insurance sales.
            They also wanted to get rid of the preconception that they only offered insurance to farmers. Our team created a campus-wide survey, and among the 402 survey takers, the average age was 21.</p><br>
        <div class="imagecontainer">
            <div><img src="../assets/Planbook(1).png"></div>
            <div><img src="../assets/Planbook(4).png"></div>
        </div><br>
        <div class="imagecontainer">
            <div><img src="../assets/Planbook(5).png"></div>
            <div><img src="../assets/Planbook(6).png"></div>
        </div><br>
        <p>We found that the younger demographic wasn't really interested in renter's insurance, or they just didn't know enough about it to invest in it. From these survey results, we created goals and strategies to 
        plan our initiative.</p><br>
        <h1>CONCEPTING</h1><br>
        <p>With our goals and strategies defined, it was time for me to get to work and develop a new brand identity for Farmer's Mutual. As a team, we brainstormed numerous name ideas, and we settled on 4 names:
           Nodaway Mutual, Foundation Mutual, Safeguard Mutual, and Next Door Mutual. From these names, I began sketching some logos.</p><br>
        <h1 class="center">SKETCHES</h1><br>
        <div class="container" id="knacktivesketches">
            <div class="item"><img src="../assets/IMG-4837.jpg"></div>
            <div class="item"><img src="../assets/IMG-4838.jpg"></div>
            <div class="item"><img src="../assets/IMG-4839.jpg"></div>
            <div class="item"><img src="../assets/IMG-4840.jpg"></div>
            <div class="item"><img src="../assets/IMG-4841.jpg"></div>
            <div class="item"><img src="../assets/IMG-4842.jpg"></div>
            <div class="item"><img src="../assets/IMG-4843.jpg"></div>
            <div class="item"><img src="../assets/IMG-4844.jpg"></div>
            <div class="item"><img src="../assets/IMG-4845.jpg"></div>
        </div>
        <p>After a bunch of brainstorming, looking at my sketches, and researching to see if the names we chose already existed, our team went with Next Door Mutual. It has never been used before
            and would give Farmer's Mutual a fresh take on what they're best at: local, friendly service.</p><br>
        <h1 class="center">THUMBNAILS</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/logos3-01.png"></div>
            <div><img src="../assets/finalthumbstype-01.png"></div>
        </div><br>
        <p>After thumbnailing, we narrowed our choice to one. It was then time to think of colors. We chose to use blue as our main color for trustworthiness, and green/yellow as our secondary color for a friendly, hometown feel.</p><br>
        <h1>REBRAND</h1><br>
        <p>This is when it all comes together. I created a logo, style guides, promotional content, website mock-ups, and more.</p><br>
        <h1 class="center">LOGO</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/NextDoorMutualLogo-01.png"></div>
            <div><img src="../assets/NextDoorMutualStyleGuide-01.png"></div>
        </div><br>
        <h1 class="center">MOCK-UPS</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/stationerywithname.png"></div>
            <div><img src="../assets/shirtFront.png"></div>
        </div><br>
        <div class="imagecontainer">
            <iframe class="knacktiveframe" height="720" width="1280" src="https://www.youtube.com/embed/L18Bxp3mJ_E" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div><br>
        <div class="imagecontainer">
            <iframe class="knacktiveframe" height="720" width="1280" src="https://www.youtube.com/embed/56WItyF7OmQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div><br>
        <h1 class="center">WEBSITE</h1><br>
        <div class="imagecontainer">
            <div><img src="../assets/Home.png"></div>
            <div><img src="../assets/Insurance.png"></div>
        </div><br>
        <h1>WHAT DID I LEARN?</h1><br>
        <p>It was really beneficial to work with a team where each team member had a specific role. 
            The team environment allowed me to work with my specialties and develop my interpersonal skills at the same time. 
            I am grateful for this class because it gave me the opportunity to emulate what a real world marketing agency might operate as.</p><br>
    </div><br><br>
</template>
<style scoped>
.item > img {
    width:100%;
    border-radius:8px;
    display: block;
}
</style>